import React from "react";
import { graphql } from "gatsby";
import Layout from "../Layout/Layout";
import BlogPostSmall from "../modules/Blog/post-small";
import CategoriesContainer from "../modules/Blog/CategoriesContainer";
import BreadCrumb from "../modules/Breadcrumb/BreadCrumb";
import SearchContainer from "../modules/Search/SearchContainer";

const PageCategoryTemplate = props => {
  const posts = props.data.allWordpressPost.edges;
  const url = props.location.pathname.split("/blog/category/");
  const url1 = url[1].split("/");
  const linkcurrent = props.location.pathname.split("/blog");

  const items = [
    { to: "/", label: "Home" },
    { to: "/blog", label: "Blog" },
    { to: linkcurrent[1], label: url1[0] }
  ];

  return (
    <Layout>
      <div className="blog__menu category__menu">
        <div className="container">
          <div className="categories__menu">
            <div className="categories__left">
              <CategoriesContainer props={props.data.wordpressWpApiMenusMenusItems.items} />
            </div>
            <div className="categories__right">
              <div className="search">
                <SearchContainer />
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="blog">
        <div className="container">
          <section className="blog__content">
            <div className="blog__list blog__list--category">
              <BreadCrumb crumbs={items} />
              {posts.map((option, i) => (
                <BlogPostSmall
                  id={option.node.id}
                  position={i}
                  key={option.node.id}
                  link={option.node.path}
                  excerpt={option.node.excerpt}
                  author={option.node.author}
                  title={option.node.title}
                  img={
                    option.node.featured_media &&
                    option.node.featured_media.localFile.childImageSharp.original.src
                  }
                  tags={option.node.tags}
                  date={option.node.date}
                  category={
                    typeof option.node.categories !== "undefined" ? option.node.categories[0] : null
                  }
                />
              ))}
            </div>
          </section>
        </div>
      </section>
    </Layout>
  );
};

export default PageCategoryTemplate;

// Need to change  allWordpressPost eq: to glob: or glob: to eq: during deploy,
// sudo npm start, sudo gatsby clean & sudo gatsby build
export const pageQuery = graphql`
  query currentCategoryPageQuery($id: String = "") {
    wordpressPage(id: { eq: $id }) {
      id
      title
      link
      content
    }
    wordpressWpApiMenusMenusItems(slug: { eq: "categories-menu" }) {
      items {
        title
        object_slug
        wordpress_children {
          title
          object_id
          object_slug
        }
        url
      }
    }
    allWordpressPost(filter: { categories: { elemMatch: { id: { glob: $id } } } }) {
      edges {
        node {
          id
          author {
            name
            link
            avatar_urls {
              wordpress_48
            }
          }
          categories {
            id
            name
            slug
          }
          date(formatString: "DD MMMM YYYY")
          featured_media {
            source_url
          }
          tags {
            id
            name
            path
          }
          title
          path
          excerpt
          content
          featured_media {
            id
            link
            source_url
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`;
